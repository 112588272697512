import _ from 'lodash'
import ActivityListService from '@serv/ActivityListService'
import ClinicalMilestoneService from '@serv/ClinicalMilestoneService'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import Milestone from '@model/Milestone'
import MilestoneService from '@serv/MilestoneService'
import moment from 'moment'
import PatientService from '@serv/PatientService'
import Schedule from '@model/Schedule'
import ScheduleEvent from '@model/ScheduleEvent'
import store from '@src/store/index'
import StringHelper from '@serv/StringHelper.js'
import Utils from '@serv/Utils'

/**
 * Patient journey object.
 */
const standardFields = [
    'anaesthesiaType',
    'createdTime',
    'externalId',
    'implantSlugs',
    'isActive',
    'isGlobal',
    'isPrimary',
    'isPrimaryPrevented',
    // If journeyId is provided, we'll resolve to slug using resources.journeys in resolvePatients()
    // journeySlug was provided only by BE versions before March 2023
    'journeyId',
    'journeySlug',
    'keyValues',
    'patientId', // used only to resolve back to Patient
    'patientJourneyId',
    'procedureLabel',
    'rtmEndScheduleOffsetDays',
    'side',
    'teamId'
]

class PatientJourney {
    constructor(object) {
        if (object.isActive === undefined) {
            object.isActive = true
        }

        // TODO: Remove when MSK-13437 is merged on the BE
        if (object.id) {
            object.patientJourneyId = object.id
        }

        this.setPatientJourneyFields(object)

        this.exerciseRoutineModifiers = this.exerciseRoutineModifiers || {} // map of activitySlug to modifier
        this.externalId = object.externalId || object.patientJourneyExternalId
        this.implantSlugs = this.implantSlugs || object.implantSlugs || []
        this.keyValues = this.keyValues || {}
        this.numUnreadMessages = object.numUnreadMessages

        if (object.patientJourneyMilestones) {
            this.milestones = PatientService.parseMilestonesArray(object.patientJourneyMilestones)
        }
        this.milestones = this.milestones || []
        this.surveyResults = this.surveyResults || []

        // TODO - this will NOT currently be called when logging in as a patient
        this.primaryMilestoneSlug = 'operation'
        if (!this.isGlobal && !_.isEmpty(store.state.user.owner)) {
            // Get the primaryMilestoneSlug from somewhere
            if (store.state.user.owner.keyValues) {
                this.primaryMilestoneSlug =
                    object.primaryMilestoneSlug ||
                    store.state.user.owner.keyValues.dash.charts.primaryMilestone ||
                    'operation'
            }
            // Resolve leadId from teamId
            const teamsMap = store.state.user.teams
            if (teamsMap) {
                // Defined when adding PatientJourneys after initial load
                const team = teamsMap[this.teamId]
                if (team) {
                    this.leadId = team.leadId
                }
            }
            this._updateIsPrimary()
        }
        this.scheduleEvents = [] // evaluated by rebuildScheduleEvents
        this.rtmEndScheduleOffsetDays = object.rtmEndScheduleOffsetDays || 0

        this.hasChat = object.hasChat
    }

    /**
     * Set any standard fields on the PatientJourney object.
     * Used in constructor, and by TabCpts that want to PATCH a PatientJourney.
     */
    setPatientJourneyFields(object) {
        standardFields.forEach(field => {
            if (object[field] !== undefined) {
                this[field] = object[field]
            }
        })
    }
    resolveIds() {
        const journeys = store.state.resources.journeys || {}
        const milestones = store.state.resources.milestones || []
        const teamsMap = store.state.user.teams

        // Replace PJ.journeyId with PJ.journeySlug
        if (this.journeyId != undefined) {
            const journey = Object.values(journeys).find(journey => journey.id == this.journeyId)
            if (journey) {
                this.journeySlug = journey.slug
                delete this.journeyId
            } else {
                Logging.error(`PatientJourney.journeyId ${this.journeyId} could not be found in journeys`)
            }
        }
        // Replace PJM.milestoneId with PJM.slug
        this.milestones.forEach(pjm => {
            if (pjm.milestoneId != undefined) {
                const milestone = milestones[pjm.milestoneId]
                if (milestone) {
                    pjm.slug = milestone.slug
                    delete pjm.milestoneId
                } else {
                    Logging.error(`PJM.milestoneId ${pjm.milestoneId} could not be found in milestones`)
                }
            }
        })
        // Ensure patientJourney stores leadId (which is a persona ID)
        if (!this.isGlobal) {
            if (!this.teamId) {
                Logging.warn(`PatientJourney ${this.patientJourneyId} has undefined teamId`)
            } else if (teamsMap) {
                const team = teamsMap[this.teamId]
                if (team) {
                    this.leadId = team.leadId
                } else {
                    Logging.error(
                        `PatientJourney ${this.patientJourneyId} has teamId ${this.teamId} which is not present.`
                    )
                }
            }
        }
    }

    // Get a readable version of the externalId - basically if it's UUID format, return empty string.
    get readableExternalId() {
        if (!this.externalId) {
            return ''
        }
        if (StringHelper.isUuid(this.externalId)) {
            return ''
        }

        return this.externalId
    }

    get sendbirdChannelUrl() {
        const user = store.state.user.user
        const patient = store.state.user.patients[this.patientId]

        return `${user.region}-${patient.personaId}-${this.teamId}`
    }

    /**
     * Get summary text, as a combination of the journey abbreviation, primary milestone date, perhaps side, etc.
     */
    get summaryText() {
        if (this.isGlobal) {
            return ''
        }
        const journeys = store.state.resources.journeys
        if (!journeys || !journeys[this.journeySlug]) {
            Logging.log(`Unknown journey: ${this.journeySlug}`)

            return ''
        }
        const journey = journeys[this.journeySlug]
        const dateString = this.primaryMilestone
            ? ` (${this.primaryMilestone.moment.format(Utils.readableDateFormat)})`
            : ''
        const inactiveString = this.isActive ? '' : `${Locale.getLanguageItem('patientJourneyIsInactivePrefix')}: `

        const journeyTitle = journey.procedureCode ?? journey.titleShortened

        return `${inactiveString}${journeyTitle}${dateString}`
    }

    /**
     * Return all Milestones (an array) that match the specified slug.
     */
    getMilestonesOfSlug(slug, includeInactive, subtype) {
        let milestones = this.milestones.filter(
            milestone => milestone.slug == slug && (includeInactive || milestone.isActive)
        )
        if (subtype) {
            milestones = milestones.filter(milestone => milestone.subtype == subtype)
        }

        return milestones
    }

    /**
     * Return the first Milestone that matches the specified id, or undefined if none.
     */
    getMilestoneOfId(id, includeInactive) {
        return this.milestones.find(milestone => milestone.id == id && (includeInactive || milestone.isActive))
    }

    /**
     * Return all Milestones (an array) that match the specified type.
     */
    getMilestonesOfType(type, includeInactive) {
        return this.milestones.filter(milestone => milestone.type == type && (includeInactive || milestone.isActive))
    }

    /**
     * Return the first Milestone that matches the specified slug, or undefined if none.
     * If there are multiple, we can also specify 'earliest' or 'latest' - else we return the first.
     */
    getMilestoneOfSlug(slug, qualifier, includeInactive, subtype) {
        let milestones = this.getMilestonesOfSlug(slug, includeInactive, subtype)
        if (milestones.length >= 1) {
            const nowMoment = moment()
            milestones.sort((a, b) => (a.moment || nowMoment).diff(b.moment || nowMoment))
            if (qualifier) {
                if (qualifier == 'earliest') {
                    return milestones[0]
                }

                if (qualifier == 'latest') {
                    return milestones[milestones.length - 1]
                }
            }

            return milestones[0]
        }
    }

    /**
     * Return the date of the single Milestone specified by slug, or undefined if the Milestone
     * could not be found, or the date is undefined. If there are multiple, we return the first and log an error.
     */
    getMilestoneOfSlugDate(slug, includeInactive) {
        const milestone = this.getMilestoneOfSlug(slug, includeInactive)

        return milestone ? milestone.date : undefined
    }

    /**
     * Set the date of the single Milestone specified by slug. If the Milestone could not be found, we log an error.
     * Get the primary milestone, or undefined.
     */
    get primaryMilestone() {
        return this.getMilestoneOfSlug(this.primaryMilestoneSlug)
    }

    /**
     * Get the date of the primary milestone, or undefined.
     */
    get primaryMilestoneDate() {
        return this.getMilestoneOfSlugDate(this.primaryMilestoneSlug)
    }

    /**
     * Get the moment of the primary milestone, or undefined.
     */
    get primaryMilestoneMoment() {
        const milestone = this.getMilestoneOfSlug(this.primaryMilestoneSlug)

        return milestone ? milestone.moment : undefined
    }

    /**
     * For all milestones where slug == primaryMilestoneSlug, update isPrimary by finding the one with earliest date.
     */
    _updateIsPrimary() {
        const primaryMilestones = this.getMilestonesOfSlug(this.primaryMilestoneSlug)
        if (primaryMilestones.length > 0) {
            const futureDate = Utils.dateFarFuture
            // Find milestone with min date value
            const milestoneMin = primaryMilestones.reduce(function (prev, curr) {
                const datePrev = prev.date || futureDate
                const dateCurr = curr.date || futureDate

                return dateCurr.localeCompare(datePrev) > 0 ? prev : curr
            })
            primaryMilestones.forEach(milestone => (milestone.isPrimary = milestone == milestoneMin))
        }
    }

    /**
     * Set the date of the single Milestone specified by slug.
     * If the Milestone could not be found, we log an error.
     * Returns true only if the date was set.
     */
    setMilestoneOfSlugDate(slug, date) {
        const milestones = this.getMilestonesOfSlug(slug)
        if (milestones.length >= 1) {
            if (milestones.length > 1) {
                Logging.error(`setMilestoneOfSlugDate(${slug}) returned ${milestones.length} results`)
            } else {
                milestones[0].setDate(date)
                this.listColumnsAreDirty = true

                return true
            }
        }

        return false
    }

    /**
     * Return a list of all users to whom the patient is currently referred.
     */
    getCurrentReferees() {
        const milestones = this.getMilestonesOfType(Milestone.Type.referral)
        const users = new Set()
        milestones.forEach(milestone => {
            if (!milestone.endDate) {
                const user = store.state.user.users[milestone.referredToId]
                users.add(user)
            }
        })

        return [...users]
    }

    /**
     * Add a milestone.
     */
    addMilestone(milestone, withoutRebuild = false) {
        this.milestones.push(milestone)
        this._updateIsPrimary()
        if (!withoutRebuild) {
            const patient = store.state.user.patients[this.patientId]
            patient.rebuildScheduleEvents()
            patient.rebuildClinicalMilestones()
            patient.rebuildListColumns()
        }
    }

    /**
     * Remove a milestone.
     */
    removeMilestone(milestone, withoutRebuild = false) {
        this.milestones = this.milestones.filter(m => {
            return !_.isEqual(_.omitBy({ ...m }, _.isNil), _.omitBy({ ...milestone }, _.isNil))
        })
        this._updateIsPrimary()
        if (!withoutRebuild) {
            const patient = store.state.user.patients[this.patientId]
            patient.rebuildScheduleEvents()
            patient.rebuildClinicalMilestones()
            patient.rebuildListColumns()
        }
    }

    /**
     * Set a milestone date (string).
     */
    setMilestoneDate(milestone, date, withoutRebuild = false) {
        if (milestone.date != date) {
            milestone.setDate(date)
            this._updateIsPrimary()
            if (!withoutRebuild) {
                const patient = store.state.user.patients[this.patientId]
                patient.rebuildScheduleEvents()
                patient.rebuildClinicalMilestones()
                patient.rebuildListColumns()
            }
        }
    }

    /**
     * Set multiple milestone dates (from strings).
     */
    setMilestonesDates(milestones, dates, withoutRebuild = false) {
        if (milestones.length != dates.length) {
            Logging.error(`setMilestonesDates called with unequal array length: ${milestones.length}, ${dates.length}`)

            return
        }
        for (let i = 0; i < milestones.length; i++) {
            if (milestones[i].date != dates[i]) {
                milestones[i].setDate(dates[i])
            }
        }
        this._updateIsPrimary()
        if (!withoutRebuild && milestones.length > 0) {
            const patient = store.state.user.patients[this.patientId]
            patient.rebuildScheduleEvents()
            patient.rebuildClinicalMilestones()
            patient.rebuildListColumns()
        }
    }

    // Does schedule specify filterMilestoneSlug, and a matching milestone has a date?
    isScheduleFiltered(schedule) {
        if (schedule.filterMilestoneSlug) {
            const milestones = this.getMilestonesOfSlug(schedule.filterMilestoneSlug)
            if (milestones.length > 0) {
                return true
            }
        }

        return false
    }

    /**
     * Build scheduleEvents array.
     * Ported from mobile JourneyManager.rebuildScheduleEvents(), with some differences:
     * - We do NOT limit to active events, i.e. those that are scheduled "now"
     * - If a schedule is repeatingRelative with a period of 1, we add only a single event, as a marker
     */
    rebuildScheduleEvents(always) {
        const owner = store.state.user.owner
        if (!always && !owner.requiresPatientJourneyScheduleEvents) {
            return
        }
        let scheduleEvents = []
        const journeys = store.state.resources.journeys
        const journey = journeys[this.journeySlug]
        const patient = store.state.user.patients[this.patientId]
        const activities = PatientService.getPatientJourneyActivities(patient, this)
        for (const activity of activities) {
            const schedule = Schedule.get(activity.scheduleSlug)

            // Ignore activities that are simply templates
            if (schedule.isNever) {
                continue
            }
            // Does schedule specify filterMilestoneSlug, and a matching milestone has a date?
            if (this.isScheduleFiltered(schedule)) {
                continue
            }
            // CUSTOM CODE FOR SPECIFIC MILESTONES
            // Ignore if milestone is 'invitation' and milestone 'operation/treatment' has a valid date
            if (
                schedule.milestone == 'invitation' &&
                (!!this.getMilestoneOfSlug('operation') || !!this.getMilestoneOfSlug('treatment'))
            ) {
                continue
            }
            // For each Milestone the Schedule resolves to
            let milestones
            if (MilestoneService.isMilestoneSlugGlobal(schedule.milestone)) {
                milestones = patient.globalJourney.getMilestonesOfSlug(schedule.milestone)
            } else {
                milestones = schedule.qualifier
                    ? [
                          this.getMilestoneOfSlug(
                              schedule.milestone,
                              schedule.qualifier,
                              false,
                              schedule.milestoneSubtype
                          )
                      ]
                    : this.getMilestonesOfSlug(schedule.milestone, false, schedule.milestoneSubtype)
                // Check milestone has patientJourneyMilestoneId. this ensures we do not schedule events relative to a
                // milestone that the backend does not know about
                milestones = milestones.filter(milestone => milestone.patientJourneyMilestoneId != 0)
            }

            for (const milestone of milestones) {
                let milestoneDate = milestone.date
                // Special case: use estimated discharge date
                if (milestone.slug == 'discharge') {
                    const opDate = this.getMilestoneOfSlugDate('operation')
                    if (opDate && journey.defaultLos != undefined) {
                        milestoneDate = moment(opDate).addDays(journey.defaultLos)
                    }
                }
                // Get all ScheduleEvents using this milestone (may be several, if Schedule is repeating)
                const events = this.getScheduleEventsForJourneyActivityMilestoneAtDate(
                    journey,
                    activity,
                    milestone,
                    milestoneDate
                )
                scheduleEvents = [...scheduleEvents, ...events]
            }
        }
        scheduleEvents.sort((a, b) => a.startDate < b.startDate)
        this.scheduleEvents = scheduleEvents
    }

    // Calculate which patient survey results fit within each ScheduleEvent
    resolveScheduleEvents() {
        const inputResults = (this.surveyResults || []).filter(surveyResult => {
            const content = store.state.content.content[surveyResult.surveySlug]
            if (!content) {
                Logging.error(`Could not find survey: ${surveyResult.surveySlug}`)

                return
            }
            // Same isValid definition as SurveyService.filterPatientJourneyActivitiesToWebSurveysScheduledNow()
            const isValid =
                content &&
                (content.isPromSurvey || content.isClinicalSurvey || content.isPremSurvey || content.isFeedbackSurvey)

            return isValid
        })
        const patient = store.state.user.patients[this.patientId]
        this.scheduleEvents.forEach(event => {
            event.calculateMatchingSurveyResults(patient.surveyResults || [], inputResults, this)
        })
    }

    // Does the specified Activity have a group matching a completed result?
    activityGroupHasMatchingCompleteResult(activity, journey) {
        if (!activity.group) {
            return false
        }
        const patient = store.state.user.patients[this.patientId]
        const content = store.state.content.content[activity.contentSlug]
        const allJourneys = content?.containsTag('group-across-journeys')
        const surveyResults = allJourneys ? patient.surveyResults : this.surveyResults
        const matchingResults = surveyResults.filter(result => result.status == 'complete')
        for (let i = 0; i < matchingResults.length; i++) {
            // result journey is a match
            const result = matchingResults[i]
            const resultActivity =
                journey.activitiesMap[result.activitySlug] || ActivityListService.getActivityBySlug(result.activitySlug)
            if (resultActivity) {
                // Found Activity, match on Activity.group
                if (resultActivity.group == activity.group) {
                    return true
                }
            } else {
                // Check if activitySlug has a group value in Journey keyValues
                // NOTE: We only consider the keyValues on the journey being passed in
                const groupToSlugs = journey.keyValues?.activityGroupToSlugs
                if (groupToSlugs) {
                    if (groupToSlugs[activity.group]?.includes(result.activitySlug)) {
                        return true
                    }
                }
            }
        }
    }

    /**
     * Get an array of ScheduleEvents for a single PJM.
     * Standard relative schedules will add a single ScheduleEvent.
     * Repeating schedules will add multiple ScheduleEvents (except repeating daily, which adds a single one).
     */
    getScheduleEventsForJourneyActivityMilestoneAtDate(journey, activity, milestone, milestoneDate) {
        let events = []
        const dateMoment = moment(milestoneDate)
        const schedule = Schedule.get(activity.scheduleSlug)
        const startMoment = dateMoment.clone().add(schedule.startOffset, 'days')
        const dayOfWeekOffset = schedule.calculateDayOfWeekOffsetFromMoment(startMoment)
        startMoment.add(dayOfWeekOffset, 'days')

        // If pre-dis, include day of discharge (which may be estimated - see above)
        let endOffsetDays = schedule.endOffset
        if (activity.scheduleSlug == 'discharge' && endOffsetDays == 0) {
            endOffsetDays++
        }

        // Ignore if Activity.group is defined, and we have any completed results for same group
        if (this.activityGroupHasMatchingCompleteResult(activity, journey)) {
            return events
        }

        let endMoment = activity.scheduleSlug.includes('always')
            ? moment().add(1, 'months')
            : dateMoment.clone().add(endOffsetDays + dayOfWeekOffset, 'days')

        switch (schedule.type) {
            case Schedule.Type.relative: {
                // Add single event
                const event = new ScheduleEvent({
                    patientJourney: this,
                    journeySlug: journey.slug,
                    activitySlug: activity.slug,
                    repeatIndex: milestone.patientJourneyMilestoneId, // distinguishes events with same activitySlug but different milestone instances
                    startDate: startMoment.format(Utils.serialisedDateFormat),
                    endDate: endMoment.format(Utils.serialisedDateFormat)
                })
                events.push(event)
                break
            }

            case Schedule.Type.repeatingRelative: {
                let intervalStartMoment = startMoment.clone().add(schedule.intervalStartOffset, 'days')
                let intervalEndMoment = startMoment.clone().add(schedule.intervalEndOffset, 'days')
                let repeatIndex = 0
                if (schedule.interval == 0) {
                    schedule.interval++
                }
                // Add events until intervalEndDate is beyond main endDate
                while (intervalStartMoment < endMoment) {
                    const event = new ScheduleEvent({
                        patientJourney: this,
                        journeySlug: journey.slug,
                        activitySlug: activity.slug,
                        repeatIndex: repeatIndex,
                        startDate: intervalStartMoment.format(Utils.serialisedDateFormat),
                        endDate: intervalEndMoment.format(Utils.serialisedDateFormat)
                    })
                    events.push(event)

                    intervalStartMoment.add(schedule.interval, 'days')
                    intervalEndMoment.add(schedule.interval, 'days')
                    repeatIndex++
                }
                break
            }
        }

        return events
    }

    /**
     * From a config object which may specify 'contentSlugs' or 'contentTag', filter our array of
     * scheduleEvents and return those that match the content spec, and where the startDate/endDate includes
     * the specified moment.
     */
    getColumnScheduleEventsAtMoment(column, nowMoment) {
        // Get valid content slugs frpm config
        const contentSlugs = ClinicalMilestoneService.getContentSlugsFromSpec(column)
        // Get ScheduleEvents matching content slugs
        let events = this.scheduleEvents.filter(event => {
            const journey = store.state.resources.journeys[this.journeySlug]
            const activity = journey.activitiesMap[event.activitySlug] || {}

            return contentSlugs.includes(activity.contentSlug)
        })
        const date = nowMoment.format(Utils.serialisedDateFormat)
        events = events.filter(event => {
            return event.startDate <= date && date < event.endDate
        })

        return events
    }

    /**
     * Get the earliest ScheduleEvent that is beyond the specified moment.
     * If not found, return undefined.
     */
    getColumnNextScheduleEventBeyondMoment(column, nowMoment) {
        // Get valid content slugs frpm config
        const contentSlugs = ClinicalMilestoneService.getContentSlugsFromSpec(column)
        // Get ScheduleEvents matching content slugs
        let events = this.scheduleEvents.filter(event => {
            const journey = store.state.resources.journeys[this.journeySlug]
            const activity = journey.activitiesMap[event.activitySlug] || {}

            return contentSlugs.includes(activity.contentSlug)
        })
        const date = nowMoment.format(Utils.serialisedDateFormat)
        for (const event of events) {
            if (event.startDate > date) {
                return event
            }
        }
    }
}

export default PatientJourney
